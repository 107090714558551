import { useEffect } from "react";
import { usePlainCss } from ".";
import { useBanner } from "..";
/**
 * Do not allow scrolling when this banner is visible and add a blur effect if enabled.
 */

function useRestrictScrollWhenVisible(show) {
  const banner = useBanner();
  const {
    layout: {
      overlay,
      overlayBlur
    },
    pageRequestUuid4,
    animationVisible
  } = banner;
  const showOverlay = show && overlay && animationVisible;
  const bodyClassRcbOverlayActive = "rcb-overlay-active"; // Blur effect

  if (process.env.PLUGIN_CTX === "pro") {
    /* onlypro:start */
    // Compatibility with selectors which move the overlay container of the cookie banner under another container down to `<body`
    // and causing blurring to the cookie banner itself.
    const offCanvasWrapperSelectors = [// [Plugin Comp] https://theplusaddons.com/widgets/off-canvas/
    ".plus-offcanvas-container", // [Plugin Comp] https://powerpackelements.com/elementor-widgets/offcanvas-content/
    ".pp-offcanvas-container", // [Plugin Comp] https://wordpress.org/plugins/mobile-menu/
    ".mobmenu-push-wrap", // [Plugin Comp] https://github.com/FrDH/mmenu-js
    ".mm-page", // [Plugin Comp] https://premiumaddons.com/docs/magic-section-widget-tutorial/
    ".premium-magic-section-body-inner", // [Plugin Comp] https://wordpress.org/plugins/groovy-menu-free/
    ".gm-nav-content-wrapper", // [Plugin Comp] https://essential-addons.com/elementor/docs/content-elements/essential-addons-elementor-offcanvas/
    ".eael-offcanvas-container"];
    const bodySelectorWhenOverlayActive = "body.".concat(bodyClassRcbOverlayActive);
    usePlainCss(overlay && +overlayBlur > 0 ? ["body", // [Plugin Comp] https://bookingkit.net/de/
    "#bkBgKeepPos", // [Plugin Comp] https://themeforest.net/item/himmelen-personal-wordpress-blog-theme/13041311
    ".st-sidebar-content-inner", // [Plugin Comp] https://slickmenu.net/
    ".sm-content-inner"].concat(offCanvasWrapperSelectors).map(bodyId => "".concat(bodyId === "body" ? bodySelectorWhenOverlayActive : "".concat(bodySelectorWhenOverlayActive, " ").concat(bodyId), " > :not(").concat(["#".concat(pageRequestUuid4), ".rcb-customize-banner-container", // [Plugin Comp] https://bookingkit.net/de/
    "#bkBgScrollPrevent", // [Plugin Comp] TranslatePress
    "#trp_ald_modal_container", // [Plugin Comp] https://themeforest.net/item/himmelen-personal-wordpress-blog-theme/13041311
    ".st-sidebar-container", // [Plugin Comp] https://slickmenu.net/
    ".sm-wrapper"].concat(offCanvasWrapperSelectors).join(","), ") { filter: blur(").concat(overlayBlur, "px); }")).join("") : "");
    /* onlypro:end */
  }

  return useEffect(() => {
    const {
      dataset,
      style,
      classList
    } = document.body; // Save first state

    if (dataset.rcbPreviousOverflow === undefined) {
      dataset.rcbPreviousOverflow = style.overflow;
    }

    style.overflow = showOverlay ? "hidden" : dataset.rcbPreviousOverflow;
    document.body.parentElement.style.overflow = style.overflow;

    if (showOverlay) {
      classList.add(bodyClassRcbOverlayActive);
    } else {
      classList.remove(bodyClassRcbOverlayActive);
    }
  }, [showOverlay]);
}

export { useRestrictScrollWhenVisible };